











































































import { Component, Vue, Prop } from "vue-property-decorator";
import { apiCategoryList, apiOrderStatement } from "@/api/data_center";
import { timeFormat } from "@/utils/util";
import ExportData from "@/components/export-data/data_center.vue";
import lsPagination from "@/components/ls-pagination.vue";

@Component({
    components: {
        ExportData,
        lsPagination,
    },
})
export default class overview extends Vue {
    // S Data
    categoryId = "1";
    end_time: any = new Date();
    categoryList = [
        {
            categoryId: "1",
            categoryName: "订单售后汇总表",
        },
        {
            categoryId: "2",
            categoryName: "卡券报表",
        },
        {
            categoryId: "3",
            categoryName: "积分报表",
        },
    ];

    disabled: boolean = true;
    loading: boolean = false;
    viewList: any = {};
    showList: any = { 1: [], 2: [], 3: [] };
    tableData: any = {};
    pager: any = {};
    // S Methods
    // E Methods
    getViewList() {
        this.disabled = true;
        apiCategoryList({})
            .then((res) => {
                this.viewList = res;
                for (const key in this.viewList) {
                    if (
                        Object.prototype.hasOwnProperty.call(this.viewList, key)
                    ) {
                        const table = this.viewList[key];
                        const elementList = table.elementList;
                        let _elementList = [];
                        for (const row_key in elementList) {
                            if (
                                Object.prototype.hasOwnProperty.call(
                                    elementList,
                                    row_key
                                )
                            ) {
                                const rows = elementList[row_key];
                                _elementList.push({
                                    elementId: row_key,
                                    elementName: rows,
                                });
                            }
                        }
                        table.elementList = _elementList;
                        this.showList[table.categoryId].push(table);
                    }
                }
                this.disabled = false;
            })
            .catch(() => {
                this.disabled = false;
            });
    }
    getData(viewId = "", page_no = "", page_size = "") {
        if (viewId) {
            this.tableData[viewId][2] = true;
        }
        console.log(123, viewId, this.tableData[viewId]);

        apiOrderStatement({
            categoryId: this.categoryId,
            viewId: viewId,
            page_no: page_no,
            page_size: page_size,
        })
            .then((res) => {
                const data = res;

                for (const key in data) {
                    if (Object.prototype.hasOwnProperty.call(data, key)) {
                        const table = data[key];
                        const lists = table.lists;
                        const elementList = lists.elementList;

                        this.tableData[lists.viewId] = [
                            [],
                            {
                                page_no: 1,
                                page_size: 25,
                                count: 0,
                            },
                            true,
                        ];

                        if (elementList.length > 0) {
                            this.tableData[lists.viewId][0] = elementList;
                            this.tableData[lists.viewId][1] = {
                                page_no: Number(table.page_no) || 1,
                                page_size: Number(table.page_size) || 25,
                                count: Number(table.count) || 0,
                            };
                        }
                        this.tableData[lists.viewId][2] = false;
                    }
                }
                console.log("this.tableData", this.tableData);

                this.loading = false;
                this.$forceUpdate();
            })
            .catch(() => {});
    }
    pageChange(e, viewId) {
        this.tableData[viewId][1].page_no = e;
        this.tableData[viewId][2] = true;
        this.$forceUpdate();

        this.$nextTick(() => {
            this.getData(
                viewId,
                this.tableData[viewId][1].page_no,
                this.tableData[viewId][1].page_size
            );
        });
    }
    sizeChange(e, viewId) {
        this.tableData[viewId][1].page_no = 1;
        this.tableData[viewId][1].page_size = e;
        this.tableData[viewId][2] = true;
        this.$forceUpdate();

        this.$nextTick(() => {
            this.getData(
                viewId,
                this.tableData[viewId][1].page_no,
                this.tableData[viewId][1].page_size
            );
        });
    }
    // S  life cycle
    handClick() {
        this.tableData = {};
        this.loading = true;
        this.$nextTick(() => {
            this.getData();
        });
    }
    async created() {
        this.loading = true;
        await this.getData();
        await this.getViewList();
        this.end_time = timeFormat(new Date(), "yyyy-mm-dd");
    }

    // E life cycle
}
