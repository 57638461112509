









































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { apiExportData } from "@/api/data_center";
import DatePicker from "@/components/date-picker.vue";

import LsDialog from "@/components/ls-dialog.vue";
@Component({
    components: {
        LsDialog,
        DatePicker,
    },
})
export default class ExportData extends Vue {
    $refs!: { dialog: any };
    @Prop() viewId?: number;
    time_type = "1";
    formData = { type: "1", start_time: "", end_time: "", viewId: "" };

    handleConfirm() {
        const data =
            this.time_type === "7"
                ? {
                      type: 7,
                      start_time: this.formData.start_time,
                      end_time: this.formData.end_time,
                      viewId: this.viewId,
                  }
                : {
                      type: this.formData.type,
                      viewId: this.viewId,
                  };
        apiExportData(data)
            .then(() => {
                this.$refs.dialog.close();
                this.$confirm("报表导出已提交，请前往下载中心查看", "", {
                    distinguishCancelAndClose: true,
                    confirmButtonText: "去查看",
                    cancelButtonText: "取消",
                })
                    .then(() => {
                        this.$router.push("/data_center/report/download");
                    })
                    .catch((action) => {});
            })
            .finally(() => {});
    }

    created() {}
}
